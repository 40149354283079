import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import styled, { keyframes } from 'styled-components'
import Circle from '../components/circle.js'
import HeroImage from '../images/HeroImage.png';

const animationBackground = keyframes`
  0% {
      transform: translateY(-50px)
  }
  100% {
      transform: translateY(0px)
  }
`

const animationText = keyframes`
  0% {
      opacity: 0;
      transform: translateY(-50px)
  }
  100% {
      opacity: 1;
      transform: translateY(0px)
  }
`
 const Hero = styled.div`
  z-index: 10;
  background: #fff;
  color: #051220;
  background-size: cover;
  background-position: center;
`

 const HeroText = styled.div`
  position: relative;
  top: 60%;
  max-width: 33%;
  margin: auto;
  padding: 12em 0em 0em 0em;
  text-align: left;

  @media only screen and (min-width: 375px) and (max-width: 992px) {
  max-width: 90%;
  padding: 8em 0em 4em 0em;
  }

  @media only screen and (min-width: 992px) and (max-width: 1200px) {
  max-width: 60%;
  padding: 8em 0em 0em 0em;
  }
`

 const TextIntro = styled.div`
  max-width: 100%;
  opacity: 1;
  white-space: pre-line;
  font-family: 'Inter';
  font-weight: 600;
  font-size: 1.8em;
  line-height: 1.4em;
  margin-bottom: 0.8em;
  
  @media only screen and (min-width: 375px) and (max-width: 992px) {
  max-width: 100%;
  font-size: 2em;
  line-height: 1.3em;
  }
`
//  animation: ${animationText} 3s forwards cubic-bezier(0.2, 0.8, 0.2, 1.0);

 const Text = styled.div`
  max-width: 90%;
  opacity: 1;
  font-weight: 400;
  font-size: 1em;
  white-space: pre-line;
  line-height: 1.44em;

  @media only screen and (min-width: 375px) and (max-width: 812px) {
  line-height: 1.3em;
  font-size: 1.3em;
  max-width: 100%;
  }
`
 const Link = styled.a`
    color: #0065FF;
    font-weight: 500;
    line-height: 1.44;
    text-decoration: none;
    transition: 0.4s;
`

const HeadPage = props => (
<div>
  <Hero>
    <HeroText>
      <div>
        <TextIntro>
        <Fade bottom delay={100} duration={800} distance="1em" ssrFadeout>
        Senior Product Designer with 7 years of experience in Product Design and Design Systems.
        </Fade>
        </TextIntro>
        <Text>
        <Fade bottom delay={150} duration={800} distance="1.8em" ssrFadeout>
        Now working at Livestorm from Europe 🇪🇺 {"\n"}
        <Link href="https://www.linkedin.com/in/alexandretrimoulet/" target="_blank">Get in touch →</Link>
        </Fade>
        </Text>
      </div>
    </HeroText>
  </Hero>
</div>
)

export default HeadPage