import React from 'react'
import Link from 'gatsby-link'
import staticdata from '../../staticdata.json'
import styled from 'styled-components'

import CardProject from '../components/cardProject'
import Cell from '../components/cell'
import HeroImage from '../components/heroImage'
import musicThumbnail from '../images/petit-bain-5.png'
import HeadPage from '../components/headPage'

import Layout from "../components/layout"

const CellTitle = styled.div`
    font-size: 24px;
    border-bottom: 1px solid rgba(0,0,0, 0.1);
    padding: 30px 0;
`
const SectionCaption = styled.p`
  font-weight: 600;
  width: 80%;
  margin: auto;
  margin-bottom: 2em;
  font-size: 1.4em;
  color: #051220;
  text-align: lef;
`

const SectionCellGroup = styled.div`
  max-width: 80%;
  margin: 0 auto 100px;
  padding: 0 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;

  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const ProjectsPage = () => (
	<Layout>
		<div>
			<div className="CardsList">
				<Link to="/music">
					<CardProject 
					title="Music"
					description="Celebrating 10 years of music production and sound design exploration"
					image={musicThumbnail}
					/>
				</Link>
			</div>

			<div className="Footer">
				<div className="Left">
					<p>
						Interested in working together?<br />
						<a href="mailto:alexandretrimoulet@gmail.com">Get in touch</a>.
					</p>
				</div>

				<div className="Right">
					<p className="SocialLinks">
						Find me on
						<a id="linkedin" href="https://www.linkedin.com/in/alexandretrimoulet" target="_blank"> LinkedIn</a>, 
						<a id="medium" href="https://medium.com/@AlexTrimoulet" target="_blank"> Medium</a>, 
						<a id="twitter" href="https://twitter.com/AlexTrimoulet" target="_blank"> Twitter</a>,
						<a id="soundcloud" href="https://soundcloud.com/osphoz/" target="_blank"> SoundCloud</a>
						<a id="dribbble" href="https://dribbble.com/alexandret" target="_blank"> Dribbble</a>, 
						<a id="producthunt" href="https://www.producthunt.com/@alextrimoulet" target="_blank"> Product Hunt</a>,  & 
						<a id="instagram" href="https://instagram.com/alextrmlt" target="_blank"> Instagram</a>.
					</p>
				</div>

				<div className="developedBy">Design & developed with ReactJS & GatsbyJS by Alexandre Trimoulet</div>
			</div>
		</div>
	</Layout>
)

export default ProjectsPage
