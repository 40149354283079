import React from 'react'
import styled from 'styled-components'

const CellGroup = styled.div`
    display: grid;
    grid-template-columns: 60px auto;
    grid-gap: 25px;
    align-items: top;
    min-width: 320px;
    border-bottom: 1px solid rgba(0,0,0, 0.1);
    margin: 1em 0;
`

const CellImage = styled.div`
    width: 60px;
    height: 60px;
    background: #212C4F;
    border-radius: 10px;
    background-image: url(${props => props.image});
    background-size: 60px;
`

const CellText = styled.div`
    width: 40%;
    text-align: left;
`

const CellDescription = styled.div`
    font-size: 1em;
    color: rgba(5,18,32,0.4);
    padding-top: 0.2em;
    padding-bottom: 2em;
`

const CellTitle = styled.div`
    font-size: 1.2em;
    font-weight: 600;
`


const SectionCellGroup = styled.div`
  max-width: 800px;
  margin: 0 auto 100px;
  padding: 0 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;

  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const Cell = props => (
    <CellGroup>
        <CellImage image={props.image}></CellImage>
        <CellText>
       		<CellTitle>{props.title}</CellTitle>
        	<CellDescription>{props.description}</CellDescription>
		</CellText> 
    </CellGroup>
)

export default Cell